.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	color: #fff;
	width: 100%;

	.navbar2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
		color: #fff;
		width: 100%;
	}

	.nav-brand img {
		max-width: 50px;
	}

	@media (min-width: 768px) {
		.navbar2 {
			width: auto;
		}
		.mobile-btn {
			display: none;
		}
	}

	a {
		color: #2d2d2d;
		text-decoration: none;

		:hover {
			color: rebeccapurple;
			text-decoration: underline;
		}
	}

	.logo {
		max-width: 80px;
	}

	.nav-items {
		display: flex;
		justify-content: space-evenly;
		list-style: none;
		z-index: 100;

		li {
			margin: 0 30px;
		}
	}

	@media (max-width: 768px) {
		.nav-items {
			display: none;
		}
	}

	.btn {
		text-decoration: none;
		display: inline-block;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 16px;
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
		border: 1px solid rgba(255, 255, 255, 0.3);
		font-weight: bold;
	}
}
