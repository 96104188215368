.hero-container {
	// height: 100vh;
	width: 100vw;

	background-image: url('../../assets/img/hero-bg-2.png');
	background-position: right top, left top;
	background-repeat: no-repeat, repeat;
	background-size: inherit;
	overflow: hidden;
	position: relative;

	.circle {
		position: absolute;
		top: -20%;
		right: -20%;
		width: 700px;
		height: 700px;
		border-radius: 50%;
		border: 3px solid #801eff;
		z-index: 1;

		.circle-container {
			position: absolute;
			bottom: 15%;
			left: 4%;
			border: 1px solid #801eff;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			animation: circle 5s infinite;

			@keyframes circle {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}

			img {
				width: 100px;
				height: 100px;
				border-radius: 50%;
				object-fit: cover;
			}
		}
	}
}

.hero-section_container {
	height: 80vh;
	display: flex;
	// justify-content: space-between;
	align-items: center;
	// padding: 0 3.75rem;

	.hero-section {
		width: 70%;
		display: flex;
		flex-direction: column;
		flex-flow: wrap;
		gap: 10px;

		h1 {
			font-size: 80px;
			font-family: 'Inter', sans-serif;
			font-weight: 900;
			color: #1c1c1c;
			line-height: 1.3;
		}

		p {
			margin-top: 3%;
			line-height: 2;
		}

		@media screen and (max-width: 768px) {
			width: 100%;

			h1 {
				font-size: 30px;
				line-height: 1.5;
			}
		}
	}
}

.btn {
	display: inline;
	padding: 15px 25px;
	border: none;
	font-size: 16px;
	border-radius: 50px;
	font-family: 'Poppins', sans-serif;
	cursor: pointer;
}

.btn-purple {
	margin-top: 20px;
	color: #fff;
	background: #9328ff;
	transition: all 0.3s ease-in-out;

	:hover {
		color: #7b21d6;
		background: #7b21d6;
	}
}
