.hero-container {
	// height: 100vh;
	width: 100vw;

	background-image: url('../../assets/img/hero-bg-2.png');
	background-position: right top, left top;
	background-repeat: no-repeat, repeat;
	background-size: inherit;
	overflow: hidden;
	position: relative;
	margin-bottom: 70px;

	.sec_container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.heading {
			margin: 50px 0;
			width: 50%;
			text-align: center;

			h1 {
				font-size: 50px;
				font-weight: bolder;
			}
		}

		.card {
			width: 50%;
			/* From https://css.glass */
			background: rgba(255, 255, 255, 0.2);
			border-radius: 16px;
			box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
			backdrop-filter: blur(5px);
			-webkit-backdrop-filter: blur(5px);
			border: 1px solid rgba(255, 255, 255, 0.3);
			padding: 50px;

			p,
			li {
				font-size: 18px;
				line-height: 1.5;
				// font-weight: 500;
				margin-bottom: 20px;
			}

			h2 {
				margin-top: 30px;
				margin-bottom: 10px;
			}

			.btn {
				text-decoration: none;
				display: inline-block;
			}

			.btn-primary {
				background-color: #801eff;
				margin-right: 10px;
				color: #fff;
			}

			.btn-secondary {
				background-color: #da1c57;
				margin-right: 10px;
				color: #fff;
			}
		}

		@media (max-width: 700px) {
			.card {
				width: 90%;
				padding: 20px;
			}

			.heading {
				width: 100%;
			}
		}
	}
}
