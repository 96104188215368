.partners-section__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;

	.partners-section__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;

		p {
			width: 80%;
			text-align: center;
		}

		.partner {
			display: flex;
			justify-content: space-around;
			align-items: center;
			gap: 1rem;

			img {
				width: 100px;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
