.leads-section__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;

	.leads-section__content {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			gap: 0;
		}

		.lead {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;

			&:nth-child(odd) {
				transform: translateY(50px);
			}

			.lead__image {
				position: relative;
				width: 250px;
				height: 400px;
				border-radius: 60px;
				overflow: hidden;

				img {
					width: 100%;
					height: 400px;
					object-fit: cover;
					border-radius: 60px;
				}

				&::after {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					content: '';
					background: linear-gradient(
						180deg,
						rgba(0, 0, 0, 0.0001) 50%,
						#801effac 100%
					);
				}

				.icons {
					width: 100%;
					z-index: 100;
					color: red;
					position: absolute;
					bottom: 10px;
					right: -50%;
					transform: translateX(-22%);

					a {
						margin: 0 5px;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}

			.lead__info {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 5px;

				.lead__name {
					font-size: 1.2rem;
					color: #464646;
				}

				.lead__role {
					font-size: 1rem;
					color: #000;
				}
			}
		}
	}
}
