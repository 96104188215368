.aboutus-section {
	background-color: #f7f4ff;

	.aboutus-section__container {
		display: flex;
		align-items: center;
		gap: 150px;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			gap: 0;
		}

		.aboutus-images {
			max-width: 100%;
			overflow: hidden;
			flex-basis: 50%;

			.image-row {
				display: flex;
				gap: 10px;
				height: 250px;
				margin-bottom: 20px;

				.about-img {
					object-fit: cover;
					border-radius: 20px;
				}

				.wide1 {
					flex-basis: 40%;
				}

				.wide2 {
					flex-basis: 60%;
				}
			}

			@media screen and (max-width: 768px) {
				.image-row {
					height: 180px;
				}
			}
		}

		.aboutus-content {
			flex-basis: 50%;

			.aboutus-text {
				width: 550px;
				font-size: 1.2rem;
				font-weight: 400;
				color: #000;
				margin-bottom: 20px;
				line-height: 28.18px;

				p {
					margin-bottom: 10px;
				}

				@media screen and (max-width: 768px) {
					width: 100%;
				}
			}
		}
	}
}