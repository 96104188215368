.vision-section__container {
	display: flex;
	align-items: center;
	gap: 150px;

	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
		gap: 0;
	}

	.vision-section__image {
		flex-basis: 50%;

		.vision-img {
			height: 300px;
			object-fit: cover;
			border-radius: 20px;
		}
	}

	.vision-section__content {
		flex-basis: 50%;

		.vision-text {
			width: 550px;
			font-size: 1.2rem;
			color: #000;
			margin-bottom: 20px;
			line-height: 28.18px;

			p {
				margin-bottom: 10px;
			}

			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}
	}
}
