.members-section__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;

	.members-section__content {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			gap: 0;
		}

		.member {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;

			.member__image img {
				width: 340px;
				height: 350px;
				object-fit: cover;
				border-radius: 20px;
			}

			.member__info {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 5px;

				.member__name {
					font-size: 1.2rem;
					color: #464646;
				}

				.member__role {
					font-size: 1rem;
					color: #000;
				}
			}
		}
	}
}
