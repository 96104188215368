.footer-section {
	background-color: #000000;
	color: #ffffff;

	.footer-section__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px 0;

		.logo {
			max-width: 50px;
		}

		.footer-section__socials {
			display: flex;
			gap: 20px;
			align-items: center;

			.footer-section__socials__icon {
				font-size: 1.5rem;
				color: #ffffff;
			}
		}
	}
}
