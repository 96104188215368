@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&display=swap');
$primary: #801eff;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
$primary: #801eff;
$primary-light: #ecddff;
$font-primary: 'Inter';
$font-secondary: 'Monument Extended';

* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
}

body {
	font-family: $font-primary;
}

h1,
h2,
h3 {
	font-family: 'Archivo', sans-serif;

}

/* ✨ UTILITY CLASSES */

// TEXT
$text-sizes: ('sm': '12px',
	'md': '18px',
	'lg': '25px',
);

@each $text,
$size in $text-sizes {
	.text-#{$text} {
		font-size: $size;
	}
}

// DISPLAY
$displays: 'flex',
'block',
'inline-block';

@each $display in $displays {
	.d-#{$display} {
		display: $display;
	}
}

.container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}

.section {
	padding: 100px 0px;
}

.title-container {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 20px;

	.section-title {
		font-size: 2.5rem;
		font-weight: 600;
		color: #801eff;
	}

	.line {
		height: 10px;
		width: 50px;
		background: linear-gradient(90deg, #b6a4ff 0%, #ffaaaa 100%);
		margin-top: 10px;
	}

	.line2 {
		height: 10px;
		width: 50px;
		background: linear-gradient(90deg, #ffaaaa 0%, #b6a4ff 100%);
		margin-top: 10px;
	}
}

@media screen and (max-width: 768px) {
	.container {
		padding: 0 20px;
	}

	.section {
		padding: 60px 0px;
	}
}

img {
	width: 100%;
}


.d-sm-block {
	display: none !important;

	@media (min-width: 320px) and (max-width: 480px) {

		.d-sm-block {
			display: block !important;
		}
	}
}

.d-sm-none {
	@media (min-width: 320px) and (max-width: 480px) {
		display: none !important;
	}
}